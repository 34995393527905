import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "campaign-page-middleware": () => import("/vercel/path0/middleware/campaign-page-middleware.ts"),
  "create-campaign-page-middleware": () => import("/vercel/path0/middleware/create-campaign-page-middleware.ts"),
  "profile-id-page-middleware": () => import("/vercel/path0/middleware/profile-id-page-middleware.ts"),
  "profile-page-middleware": () => import("/vercel/path0/middleware/profile-page-middleware.ts"),
  "settings-page-middleware": () => import("/vercel/path0/middleware/settings-page-middleware.ts")
}