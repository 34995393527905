import revive_payload_client_y0EFumSAO4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_QsGal47P3y from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Cl8bPGb4Ur from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_mhytO6TF8Z from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dqzLia1TYz from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zOs4m0KGnY from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_LHSWLkSVq4 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_z3ScQHWYTs from "/vercel/path0/node_modules/.pnpm/@pinia+nuxt@0.5.4_magicast@0.3.5_rollup@4.21.0_typescript@5.6.2_vue@3.5.4_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/.nuxt/components.plugin.mjs";
import prefetch_client_m7nI5TemqZ from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@22.4.1_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_hlaqk4bw45yj6shoy32yjdbj74/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_lITbq89FQM from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.33.1_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._mj5xhx3o2zofklghbij55uf4fi/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/.nuxt/sentry-client-config.mjs";
import plugin_WR8yrQ5imS from "/vercel/path0/node_modules/.pnpm/nuxt-quasar-ui@2.1.4_@quasar+extras@1.16.12_magicast@0.3.5_quasar@2.16.11_rollup@4.21.0/node_modules/nuxt-quasar-ui/dist/runtime/plugin.js";
import gtm_MVzSxF8h1r from "/vercel/path0/plugins/gtm.js";
export default [
  revive_payload_client_y0EFumSAO4,
  unhead_QsGal47P3y,
  router_Cl8bPGb4Ur,
  payload_client_mhytO6TF8Z,
  navigation_repaint_client_dqzLia1TYz,
  check_outdated_build_client_zOs4m0KGnY,
  chunk_reload_client_LHSWLkSVq4,
  plugin_vue3_z3ScQHWYTs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_m7nI5TemqZ,
  sentry_client_lITbq89FQM,
  sentry_client_config_o34nk2sJbg,
  plugin_WR8yrQ5imS,
  gtm_MVzSxF8h1r
]