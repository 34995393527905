import { default as indexutcnovDtlEMeta } from "/vercel/path0/pages/about/index.vue?macro=true";
import { default as indexj9d5pwgUqRMeta } from "/vercel/path0/pages/campaign/[id]/index.vue?macro=true";
import { default as index1lVmB4IgMRMeta } from "/vercel/path0/pages/contact-us/index.vue?macro=true";
import { default as indexcHAhGIUv6LMeta } from "/vercel/path0/pages/create-campaign/index.vue?macro=true";
import { default as indexRIIpWdUktDMeta } from "/vercel/path0/pages/dashboard/index.vue?macro=true";
import { default as indexs8g6T0dsz8Meta } from "/vercel/path0/pages/faq/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as indexvrx28GESt5Meta } from "/vercel/path0/pages/policy/index.vue?macro=true";
import { default as indexDYXnebT4TRMeta } from "/vercel/path0/pages/profile/[id]/index.vue?macro=true";
import { default as indexoQXEWKSyxYMeta } from "/vercel/path0/pages/profile/edit/index.vue?macro=true";
import { default as indexKIRFIU1ID4Meta } from "/vercel/path0/pages/settings/index.vue?macro=true";
import { default as index9OiDgfx7OcMeta } from "/vercel/path0/pages/terms/index.vue?macro=true";
import { default as index52cDHRjMHwMeta } from "/vercel/path0/pages/third-party/google/index.vue?macro=true";
import { default as indexwWoDI0lbtBMeta } from "/vercel/path0/pages/third-party/linkedin/index.vue?macro=true";
import { default as indexsgW9aTdsiwMeta } from "/vercel/path0/pages/ui-kit/index.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    meta: indexutcnovDtlEMeta || {},
    component: () => import("/vercel/path0/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: "campaign-id",
    path: "/campaign/:id()",
    meta: indexj9d5pwgUqRMeta || {},
    component: () => import("/vercel/path0/pages/campaign/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    meta: index1lVmB4IgMRMeta || {},
    component: () => import("/vercel/path0/pages/contact-us/index.vue").then(m => m.default || m)
  },
  {
    name: "create-campaign",
    path: "/create-campaign",
    meta: indexcHAhGIUv6LMeta || {},
    component: () => import("/vercel/path0/pages/create-campaign/index.vue").then(m => m.default || m)
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexRIIpWdUktDMeta || {},
    component: () => import("/vercel/path0/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: "faq",
    path: "/faq",
    meta: indexs8g6T0dsz8Meta || {},
    component: () => import("/vercel/path0/pages/faq/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "policy",
    path: "/policy",
    meta: indexvrx28GESt5Meta || {},
    component: () => import("/vercel/path0/pages/policy/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-id",
    path: "/profile/:id()",
    meta: indexDYXnebT4TRMeta || {},
    component: () => import("/vercel/path0/pages/profile/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: "profile-edit",
    path: "/profile/edit",
    meta: indexoQXEWKSyxYMeta || {},
    component: () => import("/vercel/path0/pages/profile/edit/index.vue").then(m => m.default || m)
  },
  {
    name: "settings",
    path: "/settings",
    meta: indexKIRFIU1ID4Meta || {},
    component: () => import("/vercel/path0/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: "terms",
    path: "/terms",
    meta: index9OiDgfx7OcMeta || {},
    component: () => import("/vercel/path0/pages/terms/index.vue").then(m => m.default || m)
  },
  {
    name: "third-party-google",
    path: "/third-party/google",
    meta: index52cDHRjMHwMeta || {},
    component: () => import("/vercel/path0/pages/third-party/google/index.vue").then(m => m.default || m)
  },
  {
    name: "third-party-linkedin",
    path: "/third-party/linkedin",
    meta: indexwWoDI0lbtBMeta || {},
    component: () => import("/vercel/path0/pages/third-party/linkedin/index.vue").then(m => m.default || m)
  },
  {
    name: "ui-kit",
    path: "/ui-kit",
    meta: indexsgW9aTdsiwMeta || {},
    component: () => import("/vercel/path0/pages/ui-kit/index.vue").then(m => m.default || m)
  }
]