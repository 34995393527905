export default defineNuxtPlugin((nuxtApp) => {
    if (import.meta.client) {
        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://www.googletagmanager.com/gtag/js?id=G-45GFZW5CHB';
        document.head.appendChild(script);

        window.dataLayer = window.dataLayer || [];
        function gtag() { dataLayer.push(arguments); }
        gtag('js', new Date());
        gtag('config', 'G-45GFZW5CHB');
    }
});
